import React from 'react';
import { graphql } from 'gatsby';
import { format } from 'date-fns';

import Layout from '../components/layout';

export const query = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      title
      content
      date
    }
  }
`;

const WpPostTemplate = ({ data }) => {
  const {
    wpPost: { title, content, date },
  } = data

  return (
    <Layout>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <p className="faded">Publikováno {format(new Date(date), 'dd. MM. yyyy')}</p>
    </Layout>
  )
}

export default WpPostTemplate;
